<template>
    <div>
        <a-modal width="800px" v-model="visible" title="小程序地址库"  @cancel="canceladdUrl" :footer="null">
            <a-spin :spinning="loading">
                <template>
                    <a-tabs  tabPosition="left" v-model="form.selectkey" >
                        <a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">
                            <lists :datakey="pane.key" v-if="form.selectkey==pane.key"  :name="pane.key"   @ok="okLists" ></lists>
                        </a-tab-pane>
                    </a-tabs>
                </template>
            </a-spin>
        </a-modal>
    </div>
</template>
<script>
    import lists from './lists';
    export default {
        components:{
            lists
        },
        data() {
            return {
                visible:false,
                loading: false,
                panes:[],
                confirmLoading: false,

                form: {
                    selectkey:"",
                },
                pagination: {
                    current: 1,
                    pageSize: 18, //每页中显示10条数据
                    total: 0,
                },

            }
        },
        created() {
            this.visible = true;
            this.loaddata();
        },
        methods: {
            loaddata() {
                this.loading=true;
                this.$http.api('admin/getWechatUrlCategory',{}).then(res=>{

                    this.form.selectkey=res.default_key;
                    this.panes=res.columns;
                    this.loading=false;
                });
            },
            callback(key){

                this.form.selectkey=key;
            },
            okLists(value){
                this.$emit('ok',value);
            },
            canceladdUrl(){
                this.$emit('cancel');
            }




        }

    }
</script>