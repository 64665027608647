<template>
    <div>
        <a-input  :value="watchurl"  :style="{width:inputwidth}" placeholder="小程序地址"   >
        </a-input>
        <a-button type="primary"  @click="addurl">
            选择链接
        </a-button>
        <div v-if="addUrlVisible">
            <url-lists  :visible="addUrlVisible" @cancel="canceladdUrl" @ok="changeddUrl" ></url-lists>
        </div>
    </div>

</template>
<script>
    import urlLists from './urlLists.vue';
    export default {
        components:{
            urlLists
        },
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            value:{
                type:String,
                default:''
            },

            inputwidth:{
                type:String,
                default:'320px'
            }
        },
        computed:{
            watchurl(){
                return this.value;
            }
        },
        data() {
            return {
                loading: false,
                confirmLoading: false,
                addUrlVisible:false,
                form: {

                },

            }
        },
        created() {

        },
        methods: {
            addurl(){
                this.addUrlVisible=true;
            },
            canceladdUrl(){
                this.addUrlVisible=false;
            },
            changeddUrl(value){
                this.addUrlVisible=false;
                this.$emit('input',value.url);
            }



        }

    }
</script>