<template>
    <div>
            <a-spin :spinning="loading">
                <div>
                    <div class="flex center">
                        <a-input-search placeholder="搜索" v-model="search.name" style="width: 420px" @search="onSearch" />
                    </div>
                    <div class="mt20">
                        <div class="flex wrap center" v-if="datas.length>0">
                            <div class="choose-serviceman-item flex space" v-for="(item,index) in datas">
                                <div class="flex">
                                    <div class="ml10">
                                        <div class="ft16 ftw600 cl-main text-over4">{{item.name}}</div>
                                        <div class="mt15 flex alcenter">
                                            <div class="cl-info ft12 ftw400 text-over4">{{item.url}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex alcenter center">
                                    <div class="choose-serviceman-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
                                        选择
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="flex center mt20">
                            <a-empty/>
                        </div>
                    </div>
                    <div class="flex center mt20">
                        <a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
                    </div>
                </div>
            </a-spin>
    </div>
</template>
<script>
    import {listMixin} from '@/common/mixin/list';
    export default {
            props: {
                datakey:{
                    type:String,
                    default:'',
                },
            },
            mixins:[listMixin],
            data() {
                return {
                    loading: false,
                    confirmLoading: false,
                    pagination:{
                        total:0,
                        pageSize:5,
                        current:1,
                    },
                    search:{
                        name:'',
                    },
                    datas:[],
                }
            },

            methods: {
                getLists(){
                    this.loading=true;
                    this.$http.api('admin/getWechatUrlList',{
                        category:this.datakey,
                        page:this.pagination.current,
                        limit:this.pagination.pageSize,
                        name:this.search.name,
                    }).then(res=>{
                        this.datas=res.datas;
                        this.pagination.total=res.total;
                        this.loading=false;
                    }).catch(res => {
                        this.loading=false;
                    });
                },
                onSearch(value){
                    this.pagination.current=1;

                    this.getLists()
                },
                checkIt(index){
                    if (this.loading == true) return;
                    this.loading = true;
                    let value=this.datas[index];
                    console.log(value);
                    this.$emit('ok',value);

                }
            }

    }



</script>
<style>
    .choose-serviceman-item{
        width: 420px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #EBEDF5;
        margin-bottom: 10px;
        padding: 10px;
    }

    .choose-serviceman-item img{
        width: 60px;
        height: 60px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
        border-radius: 40px;
        border: 2px solid #FFFFFF;
    }

    .choose-serviceman-item-duihao{
        width: 60px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #4772FF;
    }
    .choose-serviceman-item-select{
        width: 60px;
        height: 32px;
        background: #4772FF;
        border-radius: 4px;
        cursor: pointer;
    }
</style>