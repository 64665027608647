<template>
    <div>
        <div class="ft20 cl-main ftw600">幻灯片列表</div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div>
                        <a-button type="primary" icon="plus" @click="addTypeAct()">添加幻灯片</a-button>
                    </div>
                    <div class="form-search-box ">
                        <a-form layout="inline">
                            <a-form-item label="文章名称">
                                <a-input v-model="search.name" placeholder="请输入标题"></a-input>
                            </a-form-item>

                            <a-form-item>
                                <a-button @click="searchAct" type="primary">查询</a-button>
                                <a-button @click="cancelAct" class="ml10">取消</a-button>
                            </a-form-item>
                        </a-form>
                    </div>

                    <div class="">
                        <div class="wxb-table-gray">
                            <a-table rowKey="id" :columns="columns"  :pagination="pagination" @change="handleTableChange"
                                     :data-source="datas" :loading="loading">

                                <div slot="icon" slot-scope="icon,record" class="project-type-icon">
                                    <img v-if="icon != null" :src="icon"/>
                                </div>
                                <div slot="status" slot-scope="status,record">
                                    <a-switch :loading="switchloading"  v-model="record.statusswitch" checked-children="上架中"  un-checked-children="下架中" @change="onClickswitch(record)"  />
                                </div>
                                <div slot="category" slot-scope="category,record" >
                                    {{category.name}}
                                </div>
                                <div slot="shop" slot-scope="shop,record" class="project-type-icon">
                                    <a-tag v-if="shop" color="purple">
                                        {{ shop.name}}
                                    </a-tag>
                                    <a-tag v-else color="green">平台</a-tag>
                                </div>

                                <template slot="action" slot-scope="record,index">
                                    <div class="flex center">
                                        <a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;" @click="editTypeAct(record)">
                                                        <i class="iconfont ft14 iconedit"></i>
                                                        <span class="ml10">编辑</span>
                                                    </a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a class="menu-act" v-if="record.type!=1" href="javascript:;" @click="delTypeAct(record)">
                                                        <i class="iconfont ft14 icondelete"></i>
                                                        <span class="ml10">删除</span>
                                                    </a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                    </div>

                    <div v-if="editVisible">
                        <edit-adv :visible="editVisible" :id="id" @cancel="cancelEditType" @ok="okEditType"></edit-adv>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {listMixin} from '@/common/mixin/list';
    import editAdv from './components/EditAdv.vue';
    export default{
        mixins: [listMixin],
        components:{
            editAdv,
        },
        data(){
            return{
                loading: false,
                editVisible:false,
                switchloading:false,
                pagination: {
                    current: 1,
                    pageSize: 10, //每页中显示10条数据
                    total: 0,
                },
                id:0,
                search:{
                    name:'',
                },
                columns: [

                ],
                datas: [],
            }
        },
        methods:{
            onClickswitch(row){
                this.switchloading=true;
                const status=row.status ===0 ? 1 :0;
                try{
                    this.$http.api('platform/adv/status',{
                        id:row.id,
                        status:status
                    }).then(res=>{
                        row.status=status;
                    }).catch(res=>{
                        this.switchloading=false;
                    });

                }catch (err) {
                    this.switchloading=false;
                   console.error(err.message);
                }finally {
                    this.switchloading=false;
                }

            },
            getLists(){
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('platform/adv/lists',{
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                    name:this.search.name,
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.datas=res.list;
                    if(this.columns.length==0){
                        this.columns=res.columns;
                    }
                    this.loading=false;
                }).catch(res=>{
                    this.loading=false;
                })
            },

            delTypeAct(record){
                this.$confirm({
                    title:'确认删除这篇幻灯片吗？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('platform/adv/delete',{
                                id:record.id,
                            }).then(res=>{
                                this.$message.success('删除成功',1,()=>{
                                    this.getLists();
                                });
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },

            addTypeAct(){
                this.id=0;
                this.editVisible=true;
            },
            editTypeAct(record){
                this.id=record.id;
                this.editVisible=true;
            },
            cancelEditType(){
                this.editVisible=false;
            },
            okEditType(){
                this.editVisible=false;
                this.getLists();
            },
            handleTableChange(pagination, filters, sorter) {
                this.pagination.current = pagination.current;
                this.getLists();
            },
        }
    }
</script>

<style>
    .projetc-index-cover-img{
        width: 80px;
        height: 60px;
    }
    .project-type-icon img{
        width: 60px;
        height: 60px;
    }
</style>
