<template>
    <div>
        <a-modal :title="getTitle" :width="480" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div>
                    <div>
                        <div class="flex center">
                            <upload-img v-model="form.thumb"></upload-img>
                        </div>
                        <div class="flex center mt8">
                            <div class="ft12 ftw400 cl-notice">幻灯片图片：建议上传400x200px</div>
                        </div>
                    </div>
                    <div class="flex  mt24">
                        <a-input class="" style="width: 230px;" placeholder="幻灯片名称" v-model="form.name"
                                 :maxLength="20">
                        </a-input>
                        <a-input-number class=" ml20" style="width: 230px;" placeholder="请输入排序号(越大越靠前)"
                                        v-model="form.sort" :precision="0" :min="0"/>
                    </div>
                    <div class="mt20">
                        <add-url v-model="form.pagepath" inputwidth="260px"  ></add-url>
                    </div>
                    <div class="mt20">
                        <a-radio-group v-model="form.status">
                            <a-radio :value="1">
                                显示
                            </a-radio>
                            <a-radio :value="0">
                                隐藏
                            </a-radio>
                        </a-radio-group>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>

</template>

<script>
    import uploadImg from '../../../components/upload/upload.vue';
    import addUrl from "../../../components/addurl/addUrl.vue";
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            id: {
                type: Number,
                default: 0,
            }
        },
        components: {
            addUrl,
            uploadImg,
        },
        data() {
            return {
                loading: false,
                confirmLoading: false,

                order:[],
                form: {
                    thumb: '',
                    name: '',
                    sort: '',
                    pagepath:'',
                    status:1,
                },
                category:[]
            }
        },
        computed: {
            getTitle() {
                if (this.id == 0) {
                    return "添加幻灯片";
                } else {
                    return "编辑幻灯片"
                }
            }
        },
        created() {
            this.loaddata();
        },
        methods: {

            loaddata(){
                if(this.loading==true) return;
                if(this.id){
                    this.$http.api('platform/adv/show',{
                        id:this.id,
                    }).then(res=>{
                        if(res.detail){
                            this.form=res.detail;
                        }

                        this.loading=false;
                    }).catch(res=>{
                        console.log(res);
                        this.loading=false;
                    })
                }
                this.loading=false;

            },

            /**
             * 取消弹窗
             */
            handleCancel() {
                this.$emit("cancel");
            },

            /**
             * 提交数据
             */
            onSubmit() {
                if(this.confirmLoading==true) return;
                this.confirmLoading=true;
                this.$http.api('platform/adv/save',{
                    id:this.id,
                    name:this.form.name,
                    thumb:this.form.thumb,
                    sort:this.form.sort,
                    status:this.form.status,
                    pagepath:this.form.pagepath,
                }).then(res=>{
                    this.$message.success('保存成功',1,()=>{
                        this.confirmLoading=false;
                        this.$emit("ok");
                    })

                }).catch(res=>{
                    console.log(res);
                    this.confirmLoading=false;
                })

            },

        }
    }
</script>

<style>

</style>
